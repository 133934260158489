import React from "react";
import "./Main.css";
import AboutUs from "../AboutUs/AboutUs";
import Hotels from "../Hotels/Hotels";
import SwiperDeals from "../Swiper/Swiper";
import Popup from "../Popup/Popup";
import mainCoverLarge from "../../images/main-cover.webp";
import mainCoverSmall from "../../images/main-cover-small.webp";

const MainCover = () => {
  return (
    <main className="main">
      {/* <Popup/> */}
      <section className="main__cover">
          <div className="main__header-container">
            <h1>Hotels with Casino & Pokies for an Unforgettable Stay <span className="col-br">in Australia</span></h1>
            <p className="t-25 main__paragraph">
            Discover comfort and elegance in top destinations
            </p>
        </div>
        <div className="main__image-container mg-top">
          <img
            src={mainCoverLarge}
            alt="road and the ocean in Australia"
            width="1120"
            height="500"
            className="main__image large"
          />
          <img
            src={mainCoverSmall}
            alt="road and the ocean in Australia"
            width="320"
            height="400"
            className="main__image small"
          />
        </div>
      </section>
      <AboutUs/>
      <Hotels/>
      <SwiperDeals/>
    </main>
  );
};

export default MainCover;
