import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Swiper.css";

import dealHotelOne from "../../images/crown-towers-melbourne-hotel-deal.webp";
import dealHotelTwo from "../../images/the-star-grand-at-the-star-gold-coast-hotel-deal.webp";
import dealHotelThree from "../../images/crown-promenade-perth-hotel-deal.webp";
import dealHotelFour from "../../images/the-star-sydney-hotel-deal.webp";

const SwiperDeals = () => {
  return (
    <div className="mg-top deals__container">
      <h2 className="deals__header">Last minute deals</h2>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        breakpoints={{
          1200: {
            slidesPerView: 2,
          },
        }}
        navigation
        pagination={{ clickable: true }}
        className="deal__swiper"
      >
        <SwiperSlide className="slide">
          <div className="deal__item deal__one">
            <div className="deal__img-container">
              <img
                src={dealHotelOne}
                alt="hotel"
                title="Crown Towers Melbourne Hotel"
                width="273"
                height="273"
                className="deal__img"
              />
            </div>
            <div className="deal__info">
              <p className="deal__header  fw-500">
                Crown Towers Melbourne with Casino & Pokies
              </p>
              <p className="t-15 deal__description">
                Enjoy a luxurious stay at one of Melbourne's finest hotels. The
                deal includes free breakfasts and spa access.
              </p>
              <p className="deal__price t-15 col-br">$500 per night</p>
              <a
                href="https://www.expedia.com/Crown-Casino-Southbank.d6159118.Vacation-Attraction"
                target="_blank"
                rel="noreferrer"
                className="deal__link t-15 fw-500 col-bl"
              >
                Book now
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="deal__item deal__two">
            <div className="deal__img-container">
              <img
                src={dealHotelTwo}
                alt="hotel"
                title="The Star Grand at The Star Gold Coast Hotel"
                width="273"
                height="273"
                className="deal__img"
              />
            </div>
            <div className="deal__info bg-brown col-w">
              <p className="deal__header  fw-500">
                The Star Grand at The Star Gold Coast, Casino with Pokies
              </p>
              <p className="t-15 deal__description">
                Stay in elegant rooms with ocean views, including dinners at the
                restaurant and show tickets.
              </p>
              <p className="deal__price t-15">$450 per night</p>
              <a
                href="https://www.expedia.com.au/Gold-Coast-Hotels-The-Star-Grand-At-The-Star-Gold-Coast.h6921.Hotel-Information"
                target="_blank"
                rel="noreferrer"
                className="deal__link t-15 fw-500 col-w"
              >
                Book now
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="deal__item deal__three">
            <div className="deal__img-container">
              <img
                src={dealHotelThree}
                alt="hotel"
                title="Crown Promenade Perth"
                width="273"
                height="273"
                className="deal__img"
              />
            </div>
            <div className="deal__info">
              <p className="deal__header  fw-500">
                Crown Promenade Perth, Casino with Pokies
              </p>
              <p className="t-15 deal__description">
                Modern hotel with spacious rooms, including breakfast and
                fitness center access.
              </p>
              <p className="deal__price t-15 col-br">$400 per night</p>
              <a
                href="https://www.expedia.com.au/Perth-Hotels-Crown-Promenade-Perth-Hotel.h2354362.Hotel-Information"
                target="_blank"
                rel="noreferrer"
                className="deal__link t-15 fw-500 col-bl"
              >
                Book now
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <div className="deal__item deal__four">
            <div className="deal__img-container">
              <img
                src={dealHotelFour}
                alt="hotel"
                title="The Star Sydney Hotel"
                width="273"
                height="273"
                className="deal__img"
              />
            </div>
            <div className="deal__info bg-brown col-w">
              <p className="deal__header  fw-500">
                The Star Sydney with Casino and Pokies
              </p>
              <p className="t-15 deal__description">
                Stay in rooms with harbor views, including breakfasts and
                entertainment tickets.
              </p>
              <p className="deal__price t-15">$550 per night</p>
              <a
                href="https://www.expedia.com.au/Sydney-Hotels-The-Star-Grand-Hotel-And-Residences-Sydney.h84247.Hotel-Information"
                target="_blank"
                rel="noreferrer"
                className="deal__link t-15 fw-500 col-w"
              >
                Book now
              </a>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SwiperDeals;
