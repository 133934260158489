import React from "react";
import "./Hotels.css";
import hotelOne from "../../images/crown-towers-melbourne-hotel.webp";
import hotelTwo from "../../images/the-star-grand-at-the-star-gold-coast-hotel.webp";
import hotelThree from "../../images/crown-promenade-perth-hotel.webp";
import hotelFour from "../../images/the-star-sydney-hotel.webp";

import dot4 from "../../images/dot-4.svg";
import dot45 from "../../images/dot-4-5.svg";
import dot5 from "../../images/dot-5.svg";

const Hotels = () => {
  return (
    <section className="hotels mg-top">
      <h2>The Best Hotels where you will find Casino & Pokies</h2>
      <ul className="hotels__list">
        <li className="hotel__item hotel__one">
          <div className="hotel__img-container">
            <img
              src={hotelOne}
              alt="hotel"
              title="Crown Towers Melbourne Hotel"
              width="450"
              height="486"
              className="hotel__img"
            />
          </div>
          <div className="hotel__info">
            <h3 className="hotel__header">Crown Towers Melbourne with Casino & Pokies</h3>
            <p className="hotel__adress t-15 col-br">
              90 Federal Street, Auckland 1010, New Zealand
            </p>
            <p className="t-18 hotel__description">
              Crown Towers Melbourne offers luxurious rooms with stunning views
              of the city and the Yarra River. The hotel features a first-class
              casino, numerous restaurants, and a world-class spa.
            </p>
            <ul className="hotel__perks">
              <li className="hotel__perk-item">Luxurious rooms</li>
              <li className="hotel__perk-item">First-class casino</li>
              <li className="hotel__perk-item">Multiple restaurants</li>
              <li className="hotel__perk-item">Casino</li>
            </ul>
            <div className="hotel__bottom-container">
              <div className="hotel__rating-container">
                <p className="hotel__rating">4.0</p>
                <div className="hotel__dot-container">
                  <p className="t-15">Good</p>
                  <img src={dot4} alt="rating 4.0" />
                </div>
              </div>
              <a
                href="https://www.expedia.com/Crown-Casino-Southbank.d6159118.Vacation-Attraction"
                target="_blank"
                rel="noreferrer"
                className="hotel__link bg-brown col-w t-15 fw-500"
              >
                Book now
              </a>
            </div>
          </div>
        </li>
        <li className="hotel__item hotel__two">
          <div className="hotel__img-container">
            <img
              src={hotelTwo}
              alt="hotel"
              title="The Star Grand at The Star Gold Coast Hotel"
              width="450"
              height="486"
              className="hotel__img"
            />
          </div>
          <div className="hotel__info">
            <h3 className="hotel__header">
              The Star Grand at The Star Gold Coast, Casino with Pokies
            </h3>
            <p className="hotel__adress t-15 col-br">
              Broadbeach Island, Broadbeach QLD 4218, Australia
            </p>
            <p className="t-18 hotel__description">
              The Star Grand at The Star Gold Coast offers elegant rooms, a wide
              variety of restaurants and bars, and numerous entertainment
              options, including a casino and nightly shows.
            </p>
            <ul className="hotel__perks">
              <li className="hotel__perk-item">Elegant rooms</li>
              <li className="hotel__perk-item">Casino</li>
              <li className="hotel__perk-item">Entertainment events</li>
              <li className="hotel__perk-item">Pokies</li>
            </ul>
            <div className="hotel__bottom-container">
              <div className="hotel__rating-container">
                <p className="hotel__rating">4.5</p>
                <div className="hotel__dot-container">
                  <p className="t-15">Very Good</p>
                  <img src={dot45} alt="rating 4.5" />
                </div>
              </div>
              <a
                href="https://www.expedia.com.au/Gold-Coast-Hotels-The-Star-Grand-At-The-Star-Gold-Coast.h6921.Hotel-Information"
                target="_blank"
                rel="noreferrer"
                className="hotel__link bg-brown col-w t-15 fw-500"
              >
                Book now
              </a>
            </div>
          </div>
        </li>
        <li className="hotel__item hotel__three">
          <div className="hotel__img-container">
            <img
              src={hotelThree}
              alt="hotel"
              title="Crown Promenade Perth Hotel"
              width="450"
              height="486"
              className="hotel__img"
            />
          </div>
          <div className="hotel__info">
            <h3 className="hotel__header">Crown Promenade Perth, Casino with Pokies</h3>
            <p className="hotel__adress t-15 col-br">
              Great Eastern Hwy, Burswood WA 6100, Australia
            </p>
            <p className="t-18 hotel__description">
              Crown Promenade Perth is a modern hotel with spacious and
              comfortable rooms. The hotel features a casino, numerous
              restaurants and bars, as well as a spa and a pool.
            </p>
            <ul className="hotel__perks">
              <li className="hotel__perk-item">Spacious rooms</li>
              <li className="hotel__perk-item">Modern hotel</li>
              <li className="hotel__perk-item">Casino with Pokies</li>
              <li className="hotel__perk-item">Sports complex</li>
            </ul>
            <div className="hotel__bottom-container">
              <div className="hotel__rating-container">
                <p className="hotel__rating">5.0</p>
                <div className="hotel__dot-container">
                  <p className="t-15">Excellent</p>
                  <img src={dot5} alt="rating 5.0" />
                </div>
              </div>
              <a
                href="https://www.expedia.com.au/Perth-Hotels-Crown-Promenade-Perth-Hotel.h2354362.Hotel-Information"
                target="_blank"
                rel="noreferrer"
                className="hotel__link bg-brown col-w t-15 fw-500"
              >
                Book now
              </a>
            </div>
          </div>
        </li>
        <li className="hotel__item hotel__four">
          <div className="hotel__img-container">
            <img
              src={hotelFour}
              alt="hotel"
              title="The Star Sydney Hotel"
              width="450"
              height="486"
              className="hotel__img"
            />
          </div>
          <div className="hotel__info">
            <h3 className="hotel__header">The Star Sydney with Casino and Pokies</h3>
            <p className="hotel__adress t-15 col-br">
              80 Pyrmont St, Pyrmont NSW 2009, Australia
            </p>
            <p className="t-18 hotel__description">
              The Star Sydney offers luxurious rooms with harbor views, multiple
              restaurants serving diverse cuisine, a world-class casino, and a
              spa center
            </p>
            <ul className="hotel__perks">
              <li className="hotel__perk-item">Harbor view</li>
              <li className="hotel__perk-item">Diverse cuisine</li>
              <li className="hotel__perk-item">World-class casino and pokies</li>
            </ul>
            <div className="hotel__bottom-container">
              <div className="hotel__rating-container">
                <p className="hotel__rating">5.0</p>
                <div className="hotel__dot-container">
                  <p className="t-15">Excellent</p>
                  <img src={dot5} alt="rating 5.0" />
                </div>
              </div>
              <a
                href="https://www.expedia.com.au/Sydney-Hotels-The-Star-Grand-Hotel-And-Residences-Sydney.h84247.Hotel-Information"
                target="_blank"
                rel="noreferrer"
                className="hotel__link bg-brown col-w t-15 fw-500"
              >
                Book now
              </a>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Hotels;
