import React from "react";
import "./Footer.css";
import age from "../../images/age.svg";
import telegram from "../../images/telegram.svg";
import inst from "../../images/inst.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contacts" className="footer">
      <p className="t-15 footer__el1">
        If you want to get in touch with us or send us your comments, do so. You
        can contact us by email:<br></br>
        <a
          href="mailto:play-topratingsite@gmail.com"
          className="t-15 col-br footer__mail-link"
          target="_blank"
          rel="noreferrer"
        >
          play-topratingsite@gmail.com
        </a>
      </p>
      <div className="footer__el2">
        <p className="t-14">
          play-topratingsite.com uses cookies for the best experience. By
          visiting the website, you agree to the use of cookies.
        </p>
        <Link to="/cookies" className="t-17 footer__more-info">
          More info
        </Link>
      </div>
      <p className="t-15 footer__el3">
        All information on our website is for informational purposes only.
      </p>
      <div className="footer__link-container footer__el4">
        <img
          src={age}
          alt="age limit 18+"
          className="footer__age"
          width="64"
          height="64"
        />
        <div className="footer__social">
          <a href="#">
            <img src={telegram} alt="telegram icon" width="32" height="32" />
          </a>
          <a href="#">
            <img src={inst} alt="instagram icon" width="32" height="32" />
          </a>
        </div>
      </div>
      <Link to="/privacy" className="t-17 footer__el5">
        Privacy Policy
      </Link>
    </footer>
  );
};

export default Footer;
