import React from "react";
import "./AboutUs.css";
import aboutImgLarge from "../../images/about-us-cover.webp";
import aboutImgSmall from "../../images/about-us-cover-small.webp";

const AboutUs = () => {
  return (
    <section className="about-us mg-top">
      <div className="about__text-container">
        <h2 className="about__header">About Us</h2>
        <p className="t-20 about__paragraph">
          We are a team of experienced travelers and gaming enthusiasts
          dedicated to finding and reviewing the best casino hotels in
          Australia. Our detailed reviews will help you choose the perfect place
          for a vacation that combines comfort and gaming excitement. We strive
          to provide you with the most up-to-date information and tips to make
          your journey unforgettable.
        </p>
      </div>
      <div className="about__container mg-top">
        <div className="about__image-container">
          <img
            src={aboutImgLarge}
            alt="ocean in Australia with peoplr on the beach"
            width="1120"
            height="500"
            className="about__image large"
          />
          <img
            src={aboutImgSmall}
            alt="ocean in Australia with peoplr on the beach"
            width="320"
            height="400"
            className="about__image small"
          />
        </div>
        <ul className="about__info-container">
          <li className="bg-beige abot__info">
            <p className="t-18 fw-500">Expert Reviews</p>
            <p className="t-15">
              Our team has years of experience in evaluating casinos and hotels,
              allowing us to provide objective and informative reviews
            </p>
          </li>
          <li className="bg-brown abot__info col-w">
            <p className="t-18  fw-500">Up-to-Date Information</p>
            <p className="t-15">We constantly update our data to keep you informed of the latest changes and news in the world of casino hotels</p>
          </li>
          <li className="bg-beige abot__info">
            <p className="t-18 fw-500">User Recommendations</p>
            <p className="t-15">We take into account feedback from our users and integrate it into our reviews to provide you with the best experience</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default AboutUs;
